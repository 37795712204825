import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public apiCount: number = 0;
  private readonly _isLoading: WritableSignal<boolean> = signal<boolean>(false);
  readonly isLoading: Signal<boolean> = computed(() => this._isLoading());

  public start(): void {
    if (this.apiCount === 0) {
      this._isLoading.set(true);
    }
    this.apiCount++;
  }

  public stop(): void {
    this.apiCount--;
    if (this.apiCount === 0) {
      this._isLoading.set(false);
    }
  }
}
